.rowContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.addNewAnswerContainer{
    display: flex;
    align-items: center;
}

.addNewAnswerInput{
    border:none;
    outline: none;
    background-color: transparent;
    padding: 5px;
}

.alert-text{
    color: var(--error-color);
    font-size: small;
    text-align: left;
    margin-top: 10px;
}
.ConditionalSectionText{
    color: var(--success-color)
}

.crossButton{
    background-color: lightgray, 80%;
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: x-small;
    align-self: baseline;
}

.duplicateAnswer{
    text-align: left;
    color: var(--error-color);
}

.editTitleInputDiv{
    display: flex;
    flex-direction: row;
}

.emojiPlacementTitle{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.emojiButton{
    background-color: lightgray, 80%;
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.follow-up-condition-contatiner{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
    width: 90%;
}

.follow-up-text{
    text-align: center;
    vertical-align: middle;
}

.follow-up-buttons-container{
    display: flex;
    flex-direction: row;
}

.iconButton{
    font-size: 40px;
}

.missingAnswerText{
    color: red;
}

.no-margin {
    margin-bottom: 0;
}

.tagsDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 20px;
}

.tagsButtonAddQuestionDiv{    
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 20px;
}

.follow-up-conditions-show{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.tagsAddQuestionDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.buttonAddQuestionDiv{
    display: flex;
}
    
.questTitleDiv {
    font-size: large;
    text-align: left;
    margin-bottom: -10px;
}

.questTitleDivEdit {
    margin-bottom: 10px;
    margin-left: 12px;
    font-size: medium;
    text-align: left;
}

.questDescriptionDiv {
    font-size: small;
    text-align: left;
}

.questionTitleInput{
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.questionDescInput{
    width: 100%;
    border-color: lightgray;
    outline: none;
    background-color: transparent;
    resize: none;
    min-height: 100px;
    padding: 10px;
    border-radius: 10px;
}

.create-question-title-and-mandatory-div{
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
}

.question-create-title-div{
    width: 100%;
}

.mandatory-div{
    position: absolute;
    height: max-content;
    right: 20px;
    top: 70px;
}

.custom-select-question .react-select-question__control {
    max-width: 200px;
    width: fit-content;
    height: fit-content;
}

.add-answer-btn {
    border: none;
    border-radius: 100%;
    background-color: transparent;
    margin-right: 5px;
}

.add-answer-btn:hover{
    background-color: var(--shadow-light-color);
}

.add-answer-btn:active{
    background-color: var(--shadow-light-color);
}

.edit-buttons-question-create{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: right;
}

@media only screen and (max-width: 800px) {
    /* .tagsDiv{
        flex-direction: column !important;
        gap: 5px;
    } */
    .tagsButtonAddQuestionDiv{
        flex-direction: column !important;
        gap: 5px;
    }
}

@media only screen and (max-width: 600px){
        .custom-select-question{
        font-size: x-small;
    }
}

@media only screen and (max-width: 510px){
    #question-card{
        width: 70vw;
    }
}

.missing-conditions{
    color: var(--error-color);
}