.surveyrow-in-section{
    border-bottom: 1px solid var(--shadow-light-color);
}

.surveysection{
    height: max-content;
}

.sectioncolumn{
    display: block;
    width: 25%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 2px;
    text-align: center;
    white-space: nowrap;
    transition: all .1s linear;
    padding-right: 5px;
   

}

#header-row {
    width: 100%;
}

.row{
    padding: 10px 50px 10px 20px;
    align-items: center;
    color: var(--primary-color);
}

.sectioncolumn:first-child{
    text-align: left;
    padding-left: 20px;
}

.title-div{
    color: var(--secondary-color);
    padding-bottom: 20px;
    width: 100%;
   
}

#title{
    text-align: left;
    float: left;
}

.surveyrows{
    width: 100%;
    padding: 0;
}

#searchbar-in-surveysection{
    float:right;
    height: 30px;
    width: 230px;
}

.clear-search-button-surveysection{
    background-color: transparent;
    float: right;
    border: none;
}

.search-div-in-surveysection{
    float: right;
    justify-content: right;
    text-align: right;
    width: max-content;
}

@media  screen and (max-width: 600px) {
    #title{
        padding-bottom: 8px;
        font-size: 115%;
    }

    .title-div{
        padding-bottom: 20%;
    }

    .search-div-in-surveysection{
        flex-direction: row;
        padding-top: 8px;
        justify-content: left;
        padding-left: 5px;
    }

    .surveysection{
        padding: 10px 0 0 0;
    }

    .sectioncolumn{
        width: 33%;
    }
}
