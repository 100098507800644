.floating-section {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.keywordsDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 20px;
}
  
.SurveyTitle {
    display: flex;
    flex-direction: column;
}

.SurveyTitle h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 'semi-bold';
}
  
.SurveyTitle input[type="text"] {
    border: none;
    border-bottom: 1px solid lightgray;
    outline: none;
    font-weight: 'semi-bold';
    font-size: 36px;
    text-align: center;
}

.surveyDescription{
    font-size: 14px;
    text-align: center;   
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.surveyDescriptionEdit{
    font-size: smaller;
    width: 100%;
    border-color: lightgray;
    outline: none;
    background-color: transparent;
    resize: none;
    min-height: 80px;
    border-radius: 10px;
    padding: 10px;
}

.surveyTitleDivEdit{
    padding: 20px ;
}

.tagsDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 20px;
}
  
.create-survey-select-and-done-btn{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: right;
    display: flex;
}

.create-survey-done-btn{
    padding-right: 17px ;
    text-align: right;
}

.custom-select .react-select__control {
    min-width: 200px;
    width: fit-content;
    height: fit-content;
}

@media only screen and (max-width: 800px) {
.keywordsDiv{
    gap: 10px;
}

.custom-select .react-select__control {
    min-width: 100px;
    width: 150px;
  }

  .custom-select .react-select__option {
   font-size: smaller;
  }
}

@media only screen and (max-width: 610px) {
    .custom-select .react-select__control {
        width: 130px;
        font-size: smaller;
    }
}

@media only screen and (max-width: 600px) {
    .keywordsDiv{
        flex-direction: column;
        gap: 5px;
        width: 100%;
        margin: 0;
    }
      
    .custom-select .react-select__control {
        width: 80vw;
        float: left;
    }

    .create-survey-select-and-done-btn{
        flex-direction: column;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }
}
