.topbar{
    width: 100vw;
    top: 0;
    height: 66px;
    position: sticky;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.white, .blue{
    height: 50%;
    width: 100%;
    padding-left: 15px ;
    display: flex;
    align-items: center;
    justify-content: left;
}

.blue{
    background-color: var(--primary-color);
    justify-content: space-between;
}

.white{
    background-color: white;
}

#logo{
    height: 15px;
    border-radius: 0;
}

#actdata{
    color: var(--primary-color)
}

.topbar-text{
    padding-left: 10px;
    font-size: smaller;
    font-weight: 400;
    color: var(--shadow-medium-color);
}

#logout-button {
    cursor: pointer;
    color: rgb(235, 234, 236);
    padding-right: 15px;
}

#logout-button:hover {
    text-decoration: none;
    color: rgb(191, 191, 191);
}
