.page-link{
    color: var(--primary-color) !important;
}

.page-item.active a{
    background-color: var(--shadow-light-color);
    border-color: var(--shadow-medium-color);
}

.pagination{
    padding-top: 10px;
    float: bottom;
}
