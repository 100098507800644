.filter-search-sort{
  display: flex;
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 0;
}

.col{
  padding: 5px;
}

#search-icon-button {
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--shadow-light-color) !important;
  color: black;
  border: none;
}

#search-icon-button:hover{
  background-color: var(--secondary-color) !important;
  color: white !important;
}
  
#dropdown-search-category{
  background-color: transparent !important;
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color);
  margin-right: 5px;
}


.search-bar{
  height: calc(100%);
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
  padding: 5px;
  width: 60vw;
  margin-right: 5px;
  max-width: 250px;
}

#filter-search-container{
  width: 100%;
  padding: 0;
  margin: 0;
  gap: 5px;
}

.dropdown-search-searchbtn-div{
  display: flex;
  width: max-content;
  align-items: left;
  text-align: left;
}

.filter-sort-reset-div{
  display: flex;
  width: max-content;
  align-items: left;
  text-align: left;
  justify-content: flex-start; gap: 5px;
}

#navbarScrollingDropdown{
  padding: 7px;
  border-radius: 10px;
  background-color: red;
}

#filter-btn, #reset-btn, #navbarScrollingDropdown{
  height: 100%;
  font-size: small;
  border-radius: 20px;
  background-color: var(--shadow-light-color) !important;
  color: black;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}

#filter-btn:hover, #reset-btn:hover, #navbarScrollingDropdown:hover{
  background-color:var(--secondary-color) !important;
  color: white !important;
}

@media only screen and (max-width: 370px) {
  .search-bar{
    max-width: 180px;
  }
}
