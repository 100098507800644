.survey-instance-section-titles{
    height: fit-content;
    width: 100%;
    text-align: start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
    padding: 10px;
}

.survey-instance-section-search{
    text-align: start;
    padding-top: 10px;
}

#link-title{
    float: right;
    background-color: transparent;
    border: none;
    margin-left: -60px;
   border: 1px solid red;
   width: max-content;
   height: max-content;
}
