:root {
  --primary-color: #1F4455;
  --secondary-color: #8A9D78;
  --error-color: #D32F2F;
  --warning-color: #ED6C02;
  --success-color: #309335;
  --shadow-light-color: #EBEAEC;
  --shadow-medium-color: #BFBFBF;
  --shadow-dark-color: #7F7F7F;
}

.App {
  text-align: center;
  overflow-x: hidden;
  height: 100vh;  
}

.primary {
  background-color: #1F4455;
}

.nav-link{
  font-family: 'Roboto', sans-serif;
  color: grey !important;
}

.page {
  width: calc(100vw - 220px);
  margin-left: 220px; 
  padding: 20px;
}

/* Extra small devices (phones, 600px and down) */
/* small devices (tablets, 1024 and down) */
@media only screen and (max-width: 1024px) {
  .page {
    width: 100%;
    margin-left: 0;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 600px) {
  :root{
      font-size: smaller;
  }
}