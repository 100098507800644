.startWidget {
  width: 250px;
  height: 80%;
  max-width: 250px;
  min-height: 100px;
  height: 120px;
  max-height: 120px;
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
  
.icon-container {
  position: absolute;
  bottom: 0.7rem;
  left: 1.2rem;
}
  
.text-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
}
  
@media only screen and (max-width: 600px) {
  .startWidget {
    width:150px;
    height: 80px;
  }
}