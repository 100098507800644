#radio-select{
    margin-right: 10px;
}

.question-in-qb{
    display: flex;
}

.button-section-qb{
    display: flex;
    justify-content: right;
}

.accordion-question-in-qb{
    width: 100%;
}
