.conditionalSectionContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.condidionalSectionValueContainer{
    display: flex;
    justify-content: center;
}

.hidden{
    visibility: hidden;
}

.invisibleButton{
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

.SectionDescription{
    font-size: 14px;
    text-align: left;  
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.SectionDescriptionEdit{
    font-size: 14px;
    width: 100%;
    border-color: lightgray;
    outline: none;
    background-color: transparent;
    resize: none;
    min-height: 80px;
    border-radius: 10px;
    padding: 10px;
}

.SectionMetaDataContainer{
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
}
.SectionMetaData{
    display: flex;
    align-self: end;
}

.sectionTitleInput{
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
}

.sectionTitleDiv {
    font-size: large;
    text-align: left;
    padding: 0;
}

.sectionTitleDivEdit {
    font-size: large;
    text-align: left;
    margin-bottom: 10px;
}

.sec-order-and-btns{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.sec-order{
    display: flex;
    align-items: center;
    text-align: center;
    align-items: center;
    text-align: center;
    font-size: small;
    gap: 10px;
    flex: 1;
}

.section-create-btns{
    display: flex;
    gap: 10px;
    width: max-content;
}

.question-create-in-section{
    display: flex;
    flex-direction: row;
}

.add-new-question-in-list-section-buttons-div{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media only screen and (max-width: 600px){
    .sec-order-and-btns{
    flex-direction: column;
    }

    .section-create-btns{
        padding: 10px 0 0 0;
    }

    .sectionTitleDivEdit {
        padding: 0;
    }
}
