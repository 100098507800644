.surveyrow{
    display: flex;
}

.accordion-body{
    text-align: justify;
    border-bottom: 0.5px solid grey;
    font-size: small;
}

.info-row{
    font-size: smaller;
}

.info-row u{
    color: var(--primary-color);
    text-decoration: none;
}

.body-top{
    font-size: smaller;   
}

.body-top u{
    padding-right: 5px;
    color: var(--primary-color);
    text-decoration: none;
}

.accordion-button.collapsed{
    background-color: white!important;
}

.accordion-button{
    background-color: var(--shadow-light-color)!important;
    color: var(--primary-color)!important;
}

.column{
    display: block;
    width: 25%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 5px;
    padding-left: 5px;
    border-right: 0.5px solid grey;
    text-align: center;
    white-space: nowrap;
    transition: all .1s linear;
}

.column:last-child{
    border-right: none;
}

#column-survey-row-title{
   text-align: left;
}

#surveyrow-title-col{
    padding-left: 0;
    text-align: left;
}

.button{
    float: right;
    padding: 5px;      
}

.button-section{
    display: block;
}


.accordion{
    width: 100%;
}

#star{
    position: relative;
    justify-self: top;
    margin-left: -23px;
    background-color: transparent;
    border: none;
    height: max-content;
    padding: 0 5px 0 0;
}

#existing-surveys-sent{  
    width: 100%;
    white-space: normal;
}

.already-exists-modal-description{
    font-size: smaller;
    color: var(--shadow-dark-color);
    padding-bottom: 10px;
}

#delete-temp-btn{
    color: var(--error-color);
    background-color: transparent !important;
    border: none;
}

#delete-temp-btn:hover{
    background-color: var(--shadow-light-color) !important;
}

#add-to-bank-button{
    color: black !important;
    border: none;
    background-color: transparent !important;
}

#add-to-bank-button:hover{
    background-color: var(--shadow-light-color) !important;
}

@media only screen and (max-width: 600px) {
    .column{
        width: 33%;
    }
}

#view-survey-btn{
    background-color: transparent !important;
    color: var(--primary-color) !important;
    font-size: 1.5rem;
    padding: 0;
    border: none;
}

#export-btn{
    background-color: transparent !important;
    color: var(--primary-color) !important;
    font-size: 1.5rem;
    padding: 0;
    border: none;
}

#export-btn:hover{
    color: var(--secondary-color) !important;
}
#view-survey-btn:hover{
    color: var(--secondary-color) !important;
}

#manual-complete-btn{
    background-color: transparent !important;
    color: var(--primary-color) !important;
    font-size: 1.5rem;
    padding: 0;
    border: none;
    margin-left: 10px;
}

#manual-complete-btn:hover{
    color: var(--warning-color) !important;
    transition: all 0.01s ease;
    transition-delay: 0s;
}

/*


.addMore{
  border: none;
  width: 32px;
  height: 32px;
  background-color: #eee;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}
.addMore:hover{
  border: 1px solid #888;
  background-color: #ddd;
}
*/

