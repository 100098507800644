.answer-survey-page{
    display: flex;
    background-color: var(--shadow-light-color) ;
    height: 100%;
    width: 100%;
}



.answer-section-container{
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.answer-section-title{
    color: rgb(38, 38, 38);
    padding-top: 20px;
}

.answer-section-title-container{
    padding-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: left;

}

.answer-section-title-container-inspect{
    text-align: left;
    width: 100%;
    padding: 30px;
    padding-bottom: 0;
}

.answer-section{
    width: 100%;
    padding: 30px;
    margin-left: 300px;
    background-color: var(--shadow-light-color) ;
    height: max-content;
}

.answer-sent-in-container{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    height: 25vh;
    width: 50vh;
    border-radius: 15px;
    flex-direction: column;
}

.progress-sidebar{
    padding-top: 20px;
    padding-bottom: 20px;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction:  column;
    gap:10px
} 

.section-buttons{
    width: 90%;
    display: flex;
    justify-content: space-between; 
    gap: 10px;
    padding-top: 10px;
}

.section-button-send{
    width: 100%;
    display: flex;
    justify-content: flex-start; 
    padding: 0 0 20px 30px;
}

.section-list-questions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    width: 100%;
   
}

.section-list-questions-inspect{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    padding-top: 0;
    text-align: left;
    align-items: left;
}



.side-section-nav{
    float: left;
    width: 300px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    border-right: 1px solid var(--shadow-medium-color);
    z-index: 100;
}

.side-section-nav img {
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
    height: 20px;
    border-radius: 0;
}

.sent-in{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
}

#closed-sidebar-answerview{
    margin-left: -260px;
    overflow: hidden;
}

.toggle-sidebar-answerview{
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;  
}

.toggle-sidebar-answerview-button{
    background-color: transparent;
    border: none;
}

.survey-inspect-section-container{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.survey-inspect-question-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

#survey-inspect-question-title{
    font-style: italic;
    width: 100%;
    font-size: smaller;
    color: var(--shadow-dark-color);
}

#survey-inspect-question-answer{
    font-size: smaller;
    padding-top: 5px;
    width: 100%;
    margin-bottom: 20px;
}

#answer-survey-done-button{
    border: 1.5px solid var(--success-color);
    float: bottom !important;
    color: var(--success-color);
}

#answer-survey-done-button:hover{
    background-color: var(--success-color);
    color: white;
}

#answer-survey-done-button::after{
    background-color: var(--success-color);
}

.section-nav-button{
    background-color: white;
    border: none;
    width: 100%;
    margin-top: 10px;
    padding: 5px 0 5px 0;
    justify-content: center;
    text-align: left;
    display: flex;
    justify-content: space-between;
    color: black;
}


.section-nav-button.active {
    font-weight: bold;
}

.section-titles{
    height: max-content;
    width: 100%;
    text-align: left;
    padding: 20px;
    
}

#answer-section-closed-side{
    margin-left: 40px;
    transition: margin-left .5s;
}

#answer-section-open-side{
    transition: margin-left .5s;
}

#review-btn{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    margin-right: 10px;
}

@media only screen and (max-width: 800px) {
    #answer-section-open-side{
        margin-left: 40px;
    }
}
  
@media only screen and (max-width: 600px) {
    #side-section-nav-open{
        width: 100%;
        transition: width .2s;
    }

    #answer-section-closed-side{ 
        transition: margin-left .5s;
    }

    .answer-section-container{
        width: calc(100vw - 60px);
        align-self: center;
    }

    #answer-section-closed-side{
        margin-left: 20px;
        transition: margin-left 0s;
    }

    #progress-bar-answerview{
        width: 200px;
    }
}
