.breadcrumb-item > a {
    text-decoration: none;
    text-transform: Uppercase;
    font-size: 85%; 
    color: rgb(235, 234, 236);
}

.breadcrumb-item > a:hover {
    text-decoration: none;
    color: rgb(191, 191, 191);
}

ol.breadcrumb{
    height: 33px;    
    align-items: center;
}

.bread-div{
    height: 100%;
    position: relative;
}
