.answerQuestionContainer{
    width: 90%;
    max-width: 90%;
    padding: 0px;
    margin-bottom: 10px;
}



.answerViewQuestTitleDiv{
    display: grid;
    width: 100%;
    align-items: center;
    text-align: left;
}

.answerViewQuestTitleEmojiDiv{
    display:flex;
    align-items:center;
    margin-left: 15px;
}

.errorRequiredQuestion{
    color: var(--error-color);
    font-size: small;
    text-align: left;
    margin-top: 10px;
}

.imageContainer{
    display:flex;
}

.iconButton{
    border: none;
    padding: 7px;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-self: middle;
    background-color: transparent;
}

.inputButtons {
    display: flex;
}

.listAnswersDiv {
    display: flex;
    justify-content: left;
    padding: 3px;
}

.listAnswersTextArea{
    background-color: transparent;
    outline: none;
    border-color: lightgray;
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
    resize: none;
}

.mandatoryQuestionText{
    color: var(--error-color);
}

.optionalQuestionText{
    color: black;
    font-style: italic;
}

.questDescriptionDiv {
    font-size: small;
    text-align: left;
}

.type-text{
    margin-bottom: 5px;
    padding-left: 0;
    text-align: start;
    display: flex;    
}

.answerInputText{
    width: 100%;
    min-height: 80px;
    border-radius: 5px;
    border: 1px solid var(--shadow-medium-color);
}

.answerInput{
    width: 50%;
    float: left;
    min-height: 40px;
    border-radius: 5px;
    border: 1px solid var(--shadow-medium-color);
    padding: 5px;
}

@media only screen and (max-width: 600px) {
.answerQuestionContainer{
    width: 110%;
    padding: 0px;
}

}