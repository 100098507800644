.filter-search-sort-qb{
  display: flex;
  width: 100%;
  height: max-content;
}

.no-padding{
  padding: 2px;
  width: max-content;
  height: fit-content;
}

#filter-search-container-qb{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

#search-icon-button-qb {
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--shadow-light-color) !important;
  color: black;
  border: none;
}

#search-icon-button-qb:hover{
  background-color: var(--secondary-color) !important;
  color: white !important;
}
  
  
#dropdown-search-category-qb{
  background-color: transparent !important;
  color: black !important;
  border: 1px solid var(--secondary-color);
  padding: 4px;
}

.clear-search-button-qb{
  background-color: transparent;
  border: none;
  color: var(--shadow-dark-color);
}

.clear-search-button-qb:active{
  color: black ;
}

.clear-search-button-div-qb{
  padding-left: 0 !important;
  margin-left: -35px !important;
  padding-right: 12px;
}

.search-bar-qb{
  height: calc(100%);
  border: 1px solid green;
  border-radius: 10px;
  padding: 5px;
  width: 200px;
}

#row-custom{
  margin: 0 !important;
}


#filter-btn-qb{
  font-size: small;
  border-radius: 20px;
  background-color: var(--shadow-light-color) !important;
  color: black;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}

#filter-btn-qb:hover{
  background-color:var(--secondary-color) !important;
  color: white !important;
}

