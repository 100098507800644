.offcanvas{
    margin-top: 66px;
    transition:  0.2s linear !important;
}

.question-bank{
    float: right;
    width: fit-content;
    height: fit-content;
}

.offcanvas-header{
   justify-content: center !important;
   border-bottom: 1px solid var(--shadow-medium-color);
   background-color: var(--secondary-color);
   color: var(--primary-color);
}

.top-bar{
    height: fit-content;
    width: 100%;
}

.questions{
    padding-top: 10px;   
}

#button-offcanvas-open{
    border-radius: 100%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 10000;
    position: fixed;
    right: 340px;
    top: 74px;
    transition:  0.15s linear;
}

#button-offcanvas-hidden{
    width: 50px;
    height: 50px;
    position: fixed;
    border-radius: 100%;
    right: 20px;
    top:74px;
    z-index: 10000;
    transition:  0.2s linear;
    box-shadow: 1px 1px 1px 1px white; 
}

#add-button{
    border-radius: 100%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 10000;
    position: fixed;
    right: 10px;
    top: 74px;
    background-color: var(--success-color) !important;
    border: var(--success-color) !important;
}

@media only screen and (max-width: 400px) {
    #button-offcanvas-open{
        left: 3px;
    }
}

