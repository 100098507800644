#profile-pic{
    border-radius: 50%;
    max-width: 100px;
}

.profile-div{
    padding-top: 10px;
}

.space{
    margin-top: 1rem;
}
