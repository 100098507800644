#startpage{
    padding: 0;
}

.top-container{
    top: 0;
    max-height: max-content;
    padding: 20px ;
    border-bottom: 1px solid var(--shadow-medium-color);
    text-align: left;
    display: flex;
    align-items: center;
}

.bottom-container{
   display: flex;
    height: max-content;
}

.bottom-left{
    float: left;
    width: 50%;
    height: max-content;
    border-right: 1px solid var(--shadow-medium-color);
    padding: 10px;
}

.bottom-right{
    width: 50%;
    float: right;
    height: max-content;
    padding: 10px;
}

@media only screen and (max-width: 900px) {

    .bottom-container{
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .bottom-left{
        width: 100%;
        border-right: none;
        border-bottom: 1px solid var(--shadow-medium-color);
    }

    .bottom-right{
        width: 100%;
    }

}

@media only screen and (max-width: 1024px) {
    .top-container{
    padding-top: 50px;
    }
}



