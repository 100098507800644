.answer-view-question-container{
    width: 90%;
}

.answer-view-quest-container-title-div{
    font-size: large;
    text-align: left;
    margin-bottom: -10px;
    display: flex;
    flex-direction: row;
}

.answer-view-quest-title-div{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
}

.answer-view-quest-title-emoji-div{
    display:flex;
    align-items:center;
    margin-left: 15px;
}

.background-gray{
    background-color: lightgray;
}

.view-follow-up-conditions{
    padding: 5px;
    text-align: left;
}

.view-survey-follow-up-questions-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.show-survey-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.show-survey-title {
    font-size: 30px;
    color: var(--primary-color);
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.show-section-questions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap:10px;
    padding-bottom: 10px;
}
.show-section-sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap:10px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}

.show-survey-header-container{
    display: flex ;
    flex-direction: column;
    width: 100%;
}

.show-survey-title{
    display: flex;
    width: 100%;
    position: relative;
}

.show-survey-toggle{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    place-self: end;
    height: fit-content;
}

#toggle-accordion{
    align-self: center;
}

.type-input{
    text-align: left;
    padding-left: 0;
}

.quest-description-div {
    font-size: small;
    text-align: left;
}
