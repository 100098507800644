.side-nav{
    height: 100vh;
    width: 220px;
    text-align: start;
    border-right: 1px solid grey;
    position: fixed;
    z-index: 98;
    background-color: white;
    left: 0;
    transition: 0.5s;
}

.img-div{
    text-align: center;
    border-bottom: 1px solid grey;
}

.img{
    padding: 60px 30px 0px;
}

.name{
    padding: 10px;
}

.flex-column{
    padding: 10px;
}

.nav-link{
    color: var(--primary-color) !important;
    border-radius: 30px;
}

.nav-link:hover{
    background-color: var(--shadow-light-color);
}

.nav-link.active {
    background-color: var(--shadow-dark-color) !important;
    color: white !important;
}

#nav-dropdown{
    padding-left: 20px;
    font-size: smaller;
}

.toggle{
    position: fixed;
    z-index: 99;
}

.toggle button{
    background-color: white;
    border: 1px solid var(--shadow-medium-color);
    width: 100vw;
    text-align: left; 
}

.sidenav-open{
    left: 0;
}

.sidenav-closed{
    left: -220px;
}

#nav-item-with-dropdown{
    background-color: white !important;
    color: var(--primary-color) !important;
}

.nav-item{
    font-size: 0.9rem;
}
