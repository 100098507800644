.survey-instance{
    height: fit-content;
    width: 100%;
    border-bottom: 1px solid var(--shadow-light-color);
    text-align: start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
    padding: 10px;
}

.survey-instance:hover{
    background-color: var(--shadow-light-color);
}

.circle{
    margin-left: 20px;
    background-color: var(--shadow-medium-color);
    height: 10px;
    margin-top: 9px;
    width: 10px;
    border-radius: 100%;
    align-self: center;
}

#green-circle{
    background-color: var(--success-color) !important;
}

#orange-circle{
    background-color: orange !important;
}

#instance-url-btn{
    border: none;
   margin-left: 5px;
   background-color: transparent;
   width: max-content;
   height: max-content;
}

#instance-url-btn:hover{
    background-color: var(--shadow-medium-color);
    border-radius: 5px;
}

.col-instance{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all .1s linear;
}
