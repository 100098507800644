#searchbar-in-surveysection-instances{
    float: left;    
}

#clear-instances{
    float: left; 
}

#clear-instances-patientsearch{
    float: left;
}
