.surveybank-top{
    display: flex;
    height: fit-content;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
}

.surveybank-bottom{
    height: fit-content;
    padding: 20px;
}
