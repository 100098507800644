.active-button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .email-address {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
  
  .id-inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }
  
  .id-inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
  
  .id-title {
    font-size: 20px;
    color: var(--primary-color);
    text-decoration: underline;
  }
  .no-send-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .passive {
    color: var(--shadow-dark-color);
  }
  
  .passive-button {
    background-color: var(--shadow-dark-color);
    border-color: var(--shadow-dark-color);
  }
  
  .phone-number {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
  
  .scrollable-modal-body {
    max-height: 80vh;
    overflow-y: scroll;
  }
  
  .send-survey {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
    gap: 20px;
  }
  
  .send-survey-buttons {
    display: flex;
    align-self: flex-end;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    gap: 10px;
  }
  
  .send-survey-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .send-survey-done {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  
  .send-survey-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .send-survey-inner-container {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  
  .send-survey-title {
    font-size: 30px;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  
  
  .single {
    border-right: 1px solid var(--shadow-medium-color);
  }
  
  .select-team-div{
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }


.custom-modal .modal-content {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.url-link-modal{
  height: fit-content;
  word-wrap: break-word;
  
}