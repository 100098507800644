.listAnswersDiv {
    display: flex;
    justify-content: left;
    padding: 3px;
    align-items: center;
    gap: 5px;
}

.answerTextDiv {
    height: 100%;
    display: flex;
    justify-content: middle;
    padding-left: 10px;
}

.altTextRemoveContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-self: flex-end;
    margin-bottom: 10px;
    align-items: center;
}

.iconAltTextDiv {
    height: 100%;
    display: flex;
    padding-left: 10px;
    font-size: small;
    color: gray;
}

.iconButton{
    border: none;
    padding: 7px;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-self: middle;
    background-color: transparent;
}

.inputButtons {
    padding: 5px;
    display: flex;
    justify-content: middle;
}

.removeButton {
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-self: flex-end;
    background-color: transparent;
}

.listAnswersTextArea{
    background-color: transparent;
    width: 80%;
    outline: none;
    border-color: lightgray;
}
