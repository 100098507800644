.mysurveys-container{
    display: flex;
    height: max-content;
    flex-direction: column;
}
 
.mysurveys-survey-container{
   
    height: max-content;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid var(--shadow-medium-color);
}
 
