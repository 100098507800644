.view-survey-instances-top{
    display: flex;
    flex-direction: column;
}

.view-survey-instances-title{
    width: 100%;
    text-align: left;
    padding: 10px 10px 10px 0px;
    color: var(--secondary-color);
    float: left;
}

.view-survey-instances-title b{
    padding-left:  10px;
}

.view-survey-instances-desc{
    display: flex;
    float: left;
    text-align: left;
    padding: 20px;
    padding-top: 0;
    font-size: smaller;
    color: var(--shadow-dark-color);
    width: 100%;
}

.back-button{
   padding: 5px;
   width: 40px;
   padding-left: 0;
   background-color: transparent;
   border-radius: 5px;
   border: 1px solid var(--secondary-color);
   float: left;
}

#instance-url-btn-view{
    border: none;
    float: right;
    margin-left: 5px;
    background-color: transparent;
    width: max-content;
    height: max-content;
}

#instance-url-btn-view:hover{
    background-color: var(--shadow-medium-color);
    border-radius: 5px;
}
